import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Icon from '../Icon';

const S = {};
S.Arrow = styled.span`
  position: absolute;
  display: block;
  z-index: 20;
  padding: 10px;
  cursor: pointer;
  left: ${(props) => props.$direction === 'back' ? '0' : 'auto'};
  right: ${(props) => props.$direction === 'next' ? '0' : 'auto'};
  background: rgba(255, 255, 255, .3);
  
  &:hover {
    background: rgba(255, 255, 255, 1);  
  }
`

const Arrow = ({
  direction,
  onClick,
}) => {
  return (
    <S.Arrow $direction={direction} onClick={onClick}>
      <Icon size={24} name={direction} color='primary' />
    </S.Arrow>
  )
};

Arrow.propTypes = {
  direction: PropTypes.oneOf(['next', 'back']),
}

export default Arrow;
