import React from 'react'
import MultiCarousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import _merge from 'lodash/merge';

import themes from '../ThemeProvider/themes';

import './styles.css';
import Arrow from './Arrows';
import { useIsSSR } from 'utils/isSSR'

const { breakpoints } = themes.light.utils.grid;

const DefaultResponsive = {
  xs: {
    breakpoint: { min: 0, max: breakpoints.sm, },
    items: 1,
    slidesToSlide: 1,
  },
  sm: {
    breakpoint: { min: breakpoints.sm, max: breakpoints.md, },
    items: 1,
    slidesToSlide: 1,
  },
  md: {
    breakpoint: { min: breakpoints.sm, max: breakpoints.md },
    items: 2,
    slidesToSlide: 1,
  },
  lg: {
    breakpoint: { min: breakpoints.md, max: breakpoints.lg },
    items: 3,
    slidesToSlide: 1,
  },
  xl: {
    breakpoint: { min: breakpoints.lg, max: 3000 },
    items: 4,
    slidesToSlide: 1,
  },
};

const Carousel = React.forwardRef(({
  children,
  responsive,
  className,
  ssr,
  ...rest
}, ref) => {
  const isSSR = useIsSSR()

  if (isSSR) {
    return null;
  }

  return (
    <MultiCarousel
      className={className}
      ssr={false}
      responsive={_merge({}, DefaultResponsive, responsive)}
      customLeftArrow={(<Arrow direction='back' />)}
      customRightArrow={(<Arrow direction='next' />)}
      ref={ref}
      {...rest}
    >
      {children}
    </MultiCarousel>
  )
});

export default Carousel;
